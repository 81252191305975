<template>
  <div
    class="relative w-full h-full rounded-xl overflow-hidden bg-gray-800 text-white"
    :class="tileClass"
    @click="handleClick"
  >
    <!-- Div pour l'image de fond avec effet de flou -->
    <div
      class="absolute inset-0 bg-cover bg-center"
      :style="backgroundImageStyle"
    ></div>

    <!-- Superposition floue pour l'effet de flou -->
    <div class="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>

    <div class="absolute inset-0 flex flex-col justify-center items-center p-4">
      <div v-if="loading" class="text-center">
        <p class="text-lg">Chargement...</p>
      </div>
      <div v-else-if="error" class="text-center">
        <p class="text-red-400 text-lg">{{ error }}</p>
      </div>
      <template v-else>
        <!-- Tailles 'xs' et 'mini' -->
        <div v-if="isSmall" class="text-center">
          <i :class="['bi bi-github', iconSize, 'mb-2']"></i>
          <h3 class="text-sm font-bold">{{ repo.name }}</h3>
        </div>
        <!-- Autres tailles -->
        <div v-else class="text-center">
          <h3 class="text-xl font-bold mb-2">{{ repo.name }}</h3>
          <p v-if="repo.description" class="text-sm mb-2">
            {{ truncate(repo.description, 100) }}
          </p>
          <!-- Centrage des langues et topics -->
          <div class="flex flex-wrap gap-2 mb-2 justify-center">
            <span
              v-if="repo.language"
              class="px-2 py-1 bg-white bg-opacity-20 text-white text-xs rounded-full"
            >
              {{ repo.language }}
            </span>
            <span
              v-for="(topic, index) in repo.topics"
              :key="index"
              class="px-2 py-1 bg-white bg-opacity-20 text-white text-xs rounded-full"
            >
              {{ topic }}
            </span>
          </div>
          <div class="flex items-center justify-center text-sm mb-2">
            <span class="mr-4 flex items-center">
              <i class="bi bi-star-fill mr-1"></i> {{ repo.stargazers_count }}
            </span>
            <span class="mr-4 flex items-center">
              <i class="bi bi-eye-fill mr-1"></i> {{ repo.watchers_count }}
            </span>
            <span class="flex items-center">
              <i class="bi bi-git-branch mr-1"></i> {{ repo.forks_count }}
            </span>
          </div>
          <div class="text-xs mb-2">
            <span class="font-bold">Dernière mise à jour :</span>
            {{ formattedUpdatedAt }}
          </div>
          <a
            :href="repo.html_url"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-block w-full text-center py-2 px-3 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 text-white text-sm transition"
          >
            Voir le dépôt
          </a>
        </div>
      </template>
    </div>
  </div>

  <!-- Popup pour les tailles 'xs' et 'mini' -->
  <teleport to="body">
    <div
      v-if="showPopup"
      class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 px-10"
    >
      <div class="bg-gray-800 text-white rounded-xl p-6 max-w-md w-full">
        <h3 class="text-xl font-bold mb-2">{{ repo.name }}</h3>
        <p v-if="repo.description" class="mb-2">
          {{ repo.description }}
        </p>
        <div class="flex flex-wrap gap-2 mb-2 justify-center">
          <span
            v-if="repo.language"
            class="px-2 py-1 bg-white bg-opacity-20 text-white text-xs rounded-full"
          >
            {{ repo.language }}
          </span>
          <span
            v-for="(topic, index) in repo.topics"
            :key="index"
            class="px-2 py-1 bg-white bg-opacity-20 text-white text-xs rounded-full"
          >
            {{ topic }}
          </span>
        </div>
        <div class="flex items-center justify-center text-sm mb-4">
          <span class="mr-4 flex items-center">
            <i class="bi bi-star-fill mr-1"></i> {{ repo.stargazers_count }}
          </span>
          <span class="mr-4 flex items-center">
            <i class="bi bi-eye-fill mr-1"></i> {{ repo.watchers_count }}
          </span>
          <span class="flex items-center">
            <i class="bi bi-git-branch mr-1"></i> {{ repo.forks_count }}
          </span>
        </div>
        <div class="text-xs mb-4">
          <span class="font-bold">Dernière mise à jour :</span>
          {{ formattedUpdatedAt }}
        </div>
        <a
          :href="repo.html_url"
          target="_blank"
          rel="noopener noreferrer"
          class="block w-full text-center py-2 px-3 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 text-white text-sm transition"
        >
          Voir le dépôt sur GitHub
        </a>
        <button
          @click="showPopup = false"
          class="mt-4 w-full py-2 px-3 rounded-full bg-gray-700 hover:bg-gray-600 text-white text-sm transition"
        >
          Fermer
        </button>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from "vue";
import { getFromCache, setInCache } from "../utils/cache";

const props = defineProps({
  repoName: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: 'normal',
  },
  bgColor: {
    type: String,
    default: '',
  },
});

const repo = ref(null);
const loading = ref(true);
const error = ref(null);
const currentSize = ref(props.size);
const showPopup = ref(false);

const isSmall = computed(() => ["xs", "mini"].includes(currentSize.value));

const iconSize = computed(() => {
  switch (currentSize.value) {
    case "xs":
      return "text-2xl";
    case "mini":
      return "text-4xl";
    default:
      return "text-5xl";
  }
});

const fetchRepoData = async () => {
  const cacheKey = `github-repo-${props.repoName}`;
  const cachedData = getFromCache(cacheKey);

  if (cachedData) {
    repo.value = cachedData;
    loading.value = false;
    return;
  }

  try {
    const response = await fetch(
      `https://api.github.com/repos/${props.repoName}`
    );
    if (!response.ok) {
      throw new Error("Impossible de récupérer les informations du dépôt");
    }
    const data = await response.json();
    repo.value = data;
    setInCache(cacheKey, data);
  } catch (err) {
    console.error("Erreur lors de la récupération du dépôt :", err);
    error.value = "Erreur lors du chargement des informations du dépôt";
  } finally {
    loading.value = false;
  }
};

const handleClick = () => {
  if (isSmall.value) {
    showPopup.value = true;
  }
};

const truncate = (text, length) => {
  if (!text) return "";
  return text.length > length ? text.slice(0, length) + "..." : text;
};

const tileClass = computed(() => {
  return isSmall.value ? "cursor-pointer" : "";
});

const formattedUpdatedAt = computed(() => {
  if (!repo.value || !repo.value.updated_at) return "";
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(repo.value.updated_at).toLocaleDateString("fr-FR", options);
});

const backgroundImageStyle = computed(() => {
  if (repo.value && repo.value.owner && repo.value.owner.avatar_url) {
    return {
      backgroundImage: `url(${repo.value.owner.avatar_url})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      filter: "blur(1px)", // Application du filtre de flou
    };
  }
  return {};
});

onMounted(fetchRepoData);
</script>
