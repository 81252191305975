const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 heures en millisecondes

export const getFromCache = (key) => {
  const cached = localStorage.getItem(key);
  if (cached) {
    const { timestamp, data } = JSON.parse(cached);
    if (Date.now() - timestamp < CACHE_DURATION) {
      return data;
    }
  }
  return null;
};

export const setInCache = (key, data) => {
  const cacheItem = JSON.stringify({
    timestamp: Date.now(),
    data: data,
  });
  localStorage.setItem(key, cacheItem);
};
