<template>
  <div class="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
    <div class="max-w-5xl mx-auto">
      <header v-if="showHeader" :class="headerClasses">
        <div :class="headerContentClasses">
          <img
            :src="pageConfig.logo"
            alt="Logo"
            class="flex items-center justify-center h-52 w-52 rounded-full"
            :class="imageClasses"
          />
          <div :class="textClasses">
            <h1 class="text-4xl font-bold text-white">
              {{ pageConfig.name }}
            </h1>
            <p class="text-xl text-purple-100">{{ pageConfig.bio }}</p>
          </div>
        </div>
      </header>

      <main :class="{ 'mt-12': showHeader }">
        <TileOrderManager
          :items="pageConfig.items"
          :layout="pageConfig.layout"
          :posts="posts"
        />
      </main>

      <footer class="mt-12 text-center text-sm text-purple-200">
        <p>
          © {{ new Date().getFullYear() }} {{ pageConfig.name }} | Tous droits
          réservés
        </p>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import TileOrderManager from "./TileGrid.vue";
import pageConfig from "../pageConfig.json";

const posts = ref([]);
const isLoading = ref(true);

const showHeader = computed(() => pageConfig.headerStyle !== "none");

const headerClasses = computed(() => {
  if (!showHeader.value) return "";
  const baseClasses = "mb-12 flex ";
  switch (pageConfig.headerStyle) {
    case "left":
      return baseClasses + "justify-start";
    case "right":
      return baseClasses + "justify-end";
    case "center":
    default:
      return baseClasses + "justify-center";
  }
});

const headerContentClasses = computed(() => {
  if (!showHeader.value) return "";
  const baseClasses = "flex items-center ";
  switch (pageConfig.headerStyle) {
    case "left":
    case "right":
      return baseClasses + "flex-row";
    case "center":
    default:
      return baseClasses + "flex-col";
  }
});

const imageClasses = computed(() => {
  if (!showHeader.value) return "";
  switch (pageConfig.headerStyle) {
    case "left":
      return "mr-4";
    case "right":
      return "ml-4 order-2";
    case "center":
    default:
      return "mb-4";
  }
});

const textClasses = computed(() => {
  if (!showHeader.value) return "";
  switch (pageConfig.headerStyle) {
    case "left":
    case "right":
      return "text-left";
    case "center":
    default:
      return "text-center";
  }
});

const CACHE_KEY = "blogPostsCache";
const CACHE_EXPIRATION = 60 * 60 * 1000; // 1 hour in milliseconds

const getCachedPosts = () => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { timestamp, data } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_EXPIRATION) {
      return data;
    }
  }
  return null;
};

const setCachedPosts = (data) => {
  const cacheData = {
    timestamp: Date.now(),
    data: data,
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};

const fetchPosts = async () => {
  try {
    const cachedPosts = getCachedPosts();
    if (cachedPosts) {
      posts.value = cachedPosts;
      isLoading.value = false;
      return;
    }

    const response = await fetch(
      "/.netlify/functions/fetchPosts?page=1&size=10"
    );
    const data = await response.json();
    posts.value = data.blogContent;
    setCachedPosts(data.blogContent);
  } catch (error) {
    console.error("Error fetching posts:", error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchPosts);
</script>
