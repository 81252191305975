<template>
  <div
    v-if="post"
    class="relative w-full h-full rounded-xl shadow-lg hover:shadow-xl transition-all duration-200 overflow-hidden"
  >
    <!-- Image de fond -->
    <div
      class="absolute inset-0 bg-cover bg-center"
      :style="{ backgroundImage: `url(${post.coverImage?.url || ''})` }"
    ></div>
    <!-- Overlay -->
    <div
      class="absolute inset-0 bg-gradient-to-t from-indigo-600 to-pink-400 opacity-40"
    ></div>
    <!-- Contenu -->
    <a
      :href="`https://benoitpetit.dev/my-blog/${post.slug}`"
      target="_blank"
      rel="noopener noreferrer"
      class="relative z-10 w-full h-full flex flex-col justify-center items-center p-4 text-center"
    >
      <h3 class="text-white font-bold text-lg mb-2">
        {{ truncate(post.title, 50) }}
      </h3>
      <p v-if="post.brief" class="text-white text-sm mb-2">
        {{ truncate(post.brief, 100) }}
      </p>
      <span
        class="inline-block w-full text-center py-2 px-3 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 text-white text-sm transition-all duration-200"
      >
        Lire l'article
      </span>
    </a>
  </div>
  <div
    v-else
    class="w-full h-full rounded-xl shadow-lg flex items-center justify-center bg-purple-300"
  >
    <p class="text-white text-lg">Chargement de l'article...</p>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
  posts: {
    type: Array,
    default: () => [], // Valeur par défaut pour éviter undefined
  },
});

// Recherche de l'article correspondant par slug
const post = computed(() => props.posts.find((p) => p.slug === props.slug));

const truncate = (text, length) => {
  if (!text) return "";
  return text.length > length ? text.slice(0, length) + "..." : text;
};
</script>
