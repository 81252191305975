<template>
  <LinkTree />
</template>

<script setup>
import { onMounted, watch } from "vue";
import LinkTree from "./components/LinkTree.vue";
import pageConfig from "./pageConfig.json";

const updateMetaTags = () => {
  document.title = pageConfig.seo.title;
  document.querySelector("html").setAttribute("lang", pageConfig.seo.locale);

  const metaTags = [
    { name: "description", content: pageConfig.seo.description },
    { name: "keywords", content: pageConfig.seo.keywords.join(", ") },
    { name: "author", content: pageConfig.seo.author },
    { property: "og:title", content: pageConfig.seo.title },
    { property: "og:description", content: pageConfig.seo.description },
    { property: "og:image", content: pageConfig.seo.image },
    { property: "og:url", content: pageConfig.seo.canonicalUrl },
    { property: "og:site_name", content: pageConfig.seo.siteName },
    { property: "og:locale", content: pageConfig.seo.locale },
    { name: "twitter:card", content: pageConfig.seo.twitterCardType },
    { name: "twitter:creator", content: pageConfig.seo.twitterHandle },
    { name: "twitter:title", content: pageConfig.seo.title },
    { name: "twitter:description", content: pageConfig.seo.description },
    { name: "twitter:image", content: pageConfig.seo.image },
  ];

  metaTags.forEach((tag) => {
    let element = document.querySelector(
      `meta[${tag.name ? "name" : "property"}="${tag.name || tag.property}"]`
    );
    if (!element) {
      element = document.createElement("meta");
      element.setAttribute(
        tag.name ? "name" : "property",
        tag.name || tag.property
      );
      document.head.appendChild(element);
    }
    element.setAttribute("content", tag.content);
  });

  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (!canonicalLink) {
    canonicalLink = document.createElement("link");
    canonicalLink.setAttribute("rel", "canonical");
    document.head.appendChild(canonicalLink);
  }
  canonicalLink.setAttribute("href", pageConfig.seo.canonicalUrl);
};

onMounted(updateMetaTags);

// Si vous avez besoin de mettre à jour les métadonnées en fonction de changements dans pageConfig
watch(() => pageConfig.seo, updateMetaTags, { deep: true });
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-image: url("./assets/background.svg");
  background-color: #282a36;

  background-size: 500px 500px;
  background-attachment: fixed;
  background-repeat: repeat;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Optimisation du design pour les appareils mobiles */
@media (max-width: 600px) {
  body {
    background-size: 300px 300px;
  }

  #app {
    padding: 1rem;
  }
}
</style>
