<template>
  <div
    :class="[
      'relative w-full h-full rounded-xl overflow-hidden flex flex-col',
      tileClass,
    ]"
  >
    <!-- Contenu principal de la tuile -->
    <div class="flex-1 flex items-center justify-center p-4">
      <!-- État de chargement -->
      <div v-if="loading" class="text-center">
        <p class="text-lg">Chargement...</p>
      </div>

      <!-- État d'erreur -->
      <div v-else-if="error" class="text-center">
        <p class="text-red-400 text-lg">{{ error }}</p>
      </div>

      <!-- Contenu lorsque les données sont disponibles -->
      <template v-else>
        <!-- Tailles 'xs' et 'mini' -->
        <template v-if="isSmall">
          <div
            class="w-full h-full group flex flex-col items-center justify-center cursor-pointer"
            @click="handleClick"
          >
            <!-- Icône sans tooltip -->
            <div class="relative">
              <i
                :class="[
                  'bi bi-code-square',
                  iconSize,
                  'text-white transition-transform duration-300 hover:scale-110',
                ]"
              ></i>
            </div>
            <!-- Nom du gist -->
            <h3 class="text-white text-sm font-bold mt-2">
              {{ truncate(gistInfo.filename, 40) }}
            </h3>
          </div>
        </template>

        <!-- Tailles 'normal' et 'large' -->
        <template v-else>
          <a
            :href="gistInfo.url"
            target="_blank"
            rel="noopener noreferrer"
            class="w-full h-full group flex flex-col items-center justify-center"
          >
            <!-- Icône sans tooltip -->
            <div class="relative">
              <i
                :class="[
                  'bi bi-code-square',
                  iconSize,
                  'text-white transition-transform duration-300 hover:scale-110',
                ]"
              ></i>
            </div>

            <!-- Contenu détaillé -->
            <div v-if="!isSmall" class="text-center mt-2">
              <h3 class="text-white text-xl font-bold mb-2">
                {{ truncate(gistInfo.filename, 40) }}
              </h3>
              <p v-if="gistInfo.description" class="text-white text-sm mb-2">
                {{ truncate(gistInfo.description, 100) }}
              </p>
              <div class="flex flex-wrap gap-2 mb-2 justify-center">
                <span
                  v-for="(lang, index) in gistInfo.languages"
                  :key="index"
                  class="px-2 py-1 bg-white bg-opacity-20 text-white text-xs rounded-full"
                >
                  {{ lang }}
                </span>
              </div>
              <a
                :href="gistInfo.url"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-block w-full text-center py-2 px-3 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 text-white text-sm transition"
                :style="{ fontSize: buttonFontSize }"
              >
                Voir sur GitHub
              </a>
            </div>
          </a>
        </template>
      </template>
    </div>

    <!-- Popup pour les tailles 'xs' et 'mini' -->
    <teleport to="body">
      <div
        v-if="showPopup"
        class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 px-4 sm:px-10"
      >
        <div class="bg-gray-800 text-white rounded-xl p-6 max-w-md w-full">
          <h3 class="text-xl font-bold mb-2">{{ gistInfo.filename }}</h3>
          <p v-if="gistInfo.description" class="mb-2">
            {{ gistInfo.description }}
          </p>
          <div class="flex flex-wrap gap-2 mb-2">
            <span
              v-for="(lang, index) in gistInfo.languages"
              :key="index"
              class="px-2 py-1 bg-white bg-opacity-20 text-white text-xs rounded-full"
            >
              {{ lang }}
            </span>
          </div>
          <a
            :href="gistInfo.url"
            target="_blank"
            rel="noopener noreferrer"
            class="block w-full text-center py-2 px-3 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 text-white text-sm transition"
            :style="{ fontSize: buttonFontSize }"
          >
            Ouvrir sur GitHub
          </a>
          <button
            @click="showPopup = false"
            class="mt-4 w-full py-2 px-3 rounded-full bg-gray-700 hover:bg-gray-600 text-white text-sm transition"
            :style="{ fontSize: buttonFontSize }"
          >
            Fermer
          </button>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from "vue";
import { getFromCache, setInCache } from "../utils/cache";

// Définition des props attendues
const props = defineProps({
  gistId: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: "normal",
  },
});

// Variables réactives
const gistInfo = ref(null);
const loading = ref(true);
const error = ref(null);
const showPopup = ref(false);

// Computed properties
const isSmall = computed(() => ["xs", "mini"].includes(props.size));

const iconSize = computed(() => {
  switch (props.size) {
    case "xs":
      return "text-4xl";
    case "mini":
      return "text-4xl"; // Normalisé pour correspondre à LinkTile.vue
    default:
      return "text-5xl";
  }
});

const tileClass = computed(() => {
  return isSmall.value ? "cursor-pointer" : "";
});

// Styles dynamiques
const buttonFontSize = "14px"; // Utilisé dans le template

// Fonction pour tronquer le texte
const truncate = (text, length) => {
  if (!text) return "";
  return text.length > length ? text.slice(0, length) + "..." : text;
};

// Fonction pour récupérer les informations du gist
const fetchGistInfo = async () => {
  const cacheKey = `github-gist-${props.gistId}`;
  const cachedData = getFromCache(cacheKey);

  if (cachedData) {
    gistInfo.value = cachedData;
    loading.value = false;
    return;
  }

  try {
    const response = await fetch(
      `https://api.github.com/gists/${props.gistId}`
    );
    if (!response.ok) {
      throw new Error("Impossible de récupérer les informations du Gist");
    }
    const data = await response.json();
    const files = Object.values(data.files);
    const file = files[0];

    const processedInfo = {
      filename: file.filename || "Nom non disponible",
      description: data.description || "",
      languages: [...new Set(files.map((f) => f.language).filter(Boolean))],
      created_at: data.created_at || "",
      updated_at: data.updated_at || "",
      url: data.html_url || "#",
      owner: data.owner
        ? {
            login: data.owner.login,
            html_url: data.owner.html_url,
            avatar_url: data.owner.avatar_url,
          }
        : null,
    };

    gistInfo.value = processedInfo;
    setInCache(cacheKey, processedInfo);
  } catch (err) {
    console.error("Erreur lors du chargement du Gist :", err);
    error.value = "Erreur lors du chargement des informations du Gist";
  } finally {
    loading.value = false;
  }
};

// Gestionnaire de clic pour les petites tailles
const handleClick = () => {
  if (isSmall.value) {
    showPopup.value = true;
  }
};

// Appel de la fonction de récupération des données au montage du composant
onMounted(fetchGistInfo);
</script>

<style scoped>
/* Aucun changement nécessaire ici, sauf si vous souhaitez ajuster les styles */
</style>
