<template>
  <div
    class="relative w-full h-full rounded-xl overflow-hidden bg-gradient-to-t from-purple-900 via-purple-700 to-transparent text-white flex flex-col justify-center items-center p-4"
  >
    <h3 :class="['font-bold mb-2 text-center', titleClass]">
      Abonnez-vous à ma newsletter
    </h3>

    <template v-if="!isSubmitted">
      <p :class="['mb-4 text-center', descriptionClass]">
        Recevez mes derniers articles et astuces directement dans votre boîte
        mail.
      </p>
      <form @submit.prevent="handleSubmit" class="w-full">
        <input
          v-model="email"
          type="email"
          placeholder="Votre adresse e-mail"
          required
          class="w-full mb-2 p-2 rounded text-gray-800 focus:outline-none focus:ring-2 focus:ring-purple-500"
          aria-label="Votre adresse e-mail"
          :style="{ fontSize: inputFontSize }"
        />
        <button
          type="submit"
          class="w-full py-2 px-4 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 text-white transition"
          aria-label="S'abonner à la newsletter"
          :style="{ fontSize: buttonFontSize }"
        >
          S'abonner
        </button>
        <p
          class="mt-auto text-center text-gray-300"
          :style="{ fontSize: inputFontSize }"
        >
          sponsored by hashnode
        </p>
      </form>
    </template>

    <div v-else class="text-center">
      <p>{{ statusMessage }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import { getFromCache, setInCache } from "../utils/cache";

const email = ref("");
const statusMessage = ref("");
const isSubmitted = ref(false);

const props = defineProps({
  size: {
    type: String,
    default: "normal",
  },
});

const CACHE_KEY_PREFIX = "newsletterSubscription";

const handleSubmit = async () => {
  isSubmitted.value = true;
  const cacheKey = `${CACHE_KEY_PREFIX}-${email.value}`;
  const cachedStatus = getFromCache(cacheKey);

  if (cachedStatus) {
    setStatusMessage(cachedStatus);
  } else {
    const status = await subscribeToNewsletter(email.value);
    setStatusMessage(status);
    setInCache(cacheKey, status);
  }
};

const subscribeToNewsletter = async (email) => {
  try {
    const response = await fetch("/.netlify/functions/subscribeNewsletter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    if (response.ok) {
      return data.status;
    } else {
      throw new Error(data.message || "Une erreur est survenue");
    }
  } catch (error) {
    console.error("Erreur lors de l'inscription:", error);
    return "ERROR";
  }
};

const setStatusMessage = (status) => {
  switch (status) {
    case "CONFIRMED":
      statusMessage.value = "Merci de vous être abonné !";
      break;
    case "PENDING":
      statusMessage.value =
        "Vérifiez votre e-mail pour confirmer votre abonnement.";
      break;
    case "ALREADY_SUBSCRIBED":
      statusMessage.value = "Vous êtes déjà abonné à la newsletter.";
      break;
    case "ERROR":
      statusMessage.value =
        "Une erreur est survenue. Veuillez réessayer plus tard.";
      break;
    default:
      statusMessage.value = "";
  }
};

// Ajuster les styles en fonction de la taille de la tuile
const titleClass = computed(() => {
  switch (props.size) {
    case "mini":
      return "text-base";
    case "normal":
      return "text-lg";
    case "large":
    default:
      return "text-xl";
  }
});

const descriptionClass = computed(() => {
  switch (props.size) {
    case "mini":
      return "text-xs";
    case "normal":
      return "text-sm";
    case "large":
    default:
      return "text-base";
  }
});

const inputFontSize = computed(() => {
  switch (props.size) {
    case "mini":
      return "12px";
    case "normal":
      return "14px";
    case "large":
    default:
      return "16px";
  }
});

const buttonFontSize = computed(() => {
  switch (props.size) {
    case "mini":
      return "12px";
    case "normal":
      return "14px";
    case "large":
    default:
      return "16px";
  }
});
</script>
