<template>
  <div
    :class="[
      'relative w-full h-full rounded-xl overflow-hidden flex flex-col',
      tileClass,
    ]"
  >
    <!-- Contenu principal de la tuile -->
    <div class="flex-1 flex items-center justify-center">
      <!-- État de chargement -->
      <div v-if="loading" class="text-center">
        <p class="text-lg">Chargement...</p>
      </div>
      
      <!-- État d'erreur -->
      <div v-else-if="error" class="text-center">
        <p class="text-red-400 text-lg">{{ error }}</p>
      </div>
      
      <!-- Contenu lorsque les données sont disponibles -->
      <template v-else>
        <!-- Petite taille : xs, mini -->
        <template v-if="isSmall">
          <div
            class="w-full h-full group flex flex-col items-center justify-center cursor-pointer"
            @click="handleClick"
          >
            <!-- Icône avec tooltip -->
            <div class="relative">
              <i
                :class="[
                  'bi bi-box-seam',
                  iconSize,
                  'text-white transition-transform duration-300 hover:scale-110',
                ]"
              ></i>
              <!-- Tooltip -->
              <div
                v-if="packageInfo.name"
                class="absolute bottom-full mb-2 w-max px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                style="left: 50%; transform: translateX(-50%);"
              >
                {{ packageInfo.name }}
              </div>
            </div>
            <!-- Nom du package -->
            <h3 class="text-white text-sm font-bold mt-2">
              {{ truncate(packageInfo.name, 40) }}
            </h3>
          </div>
        </template>

        <!-- Taille normale et grande -->
        <template v-else>
          <a
            :href="packageInfo.npm_url"
            target="_blank"
            rel="noopener noreferrer"
            class="w-full h-full group flex flex-col items-center justify-center"
          >
            <!-- Icône avec tooltip -->
            <div class="relative">
              <i
                :class="[
                  'bi bi-box-seam',
                  iconSize,
                  'text-white transition-transform duration-300 hover:scale-110',
                ]"
              ></i>
              <!-- Tooltip -->
              <div
                v-if="packageInfo.name"
                class="absolute bottom-full mb-2 w-max px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                style="left: 50%; transform: translateX(-50%);"
              >
                {{ packageInfo.name }}
              </div>
            </div>

            <!-- Contenu détaillé -->
            <div v-if="!isSmall" class="text-center mt-2">
              <h3 class="text-white text-lg font-bold mb-1">
                {{ truncate(packageInfo.name, 40) }}
              </h3>
              <p class="text-white text-sm opacity-80">
                {{ truncate(packageInfo.description, 60) }}
              </p>
              <p class="text-white text-xs mb-1">
                <span class="font-bold">Version :</span> {{ packageInfo.version }}
              </p>
              <p class="text-white text-xs mb-1">
                <span class="font-bold">Auteur :</span> {{ packageInfo.author }}
              </p>
              <a
                :href="packageInfo.npm_url"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-block w-full text-center py-2 px-3 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 text-white text-sm transition"
                :style="{ fontSize: buttonFontSize }"
              >
                Voir sur npm
              </a>
            </div>
          </a>
        </template>
      </template>
    </div>

    <!-- Popup pour les tailles 'xs' et 'mini' -->
    <teleport to="body">
      <div
        v-if="showPopup"
        class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 px-10"
      >
        <div class="bg-gray-800 text-white rounded-xl p-6 max-w-md w-full">
          <h3 class="text-xl font-bold mb-2">{{ packageInfo.name }}</h3>
          <p class="mb-2">{{ packageInfo.description }}</p>
          <p class="mb-1">
            <span class="font-bold">Version :</span> {{ packageInfo.version }}
          </p>
          <p class="mb-1">
            <span class="font-bold">Auteur :</span> {{ packageInfo.author }}
          </p>
          <a
            :href="packageInfo.npm_url"
            target="_blank"
            rel="noopener noreferrer"
            class="block w-full text-center py-2 px-3 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 text-white text-sm transition"
            :style="{ fontSize: buttonFontSize }"
          >
            Voir sur npm
          </a>
          <button
            @click="showPopup = false"
            class="mt-4 w-full py-2 px-3 rounded-full bg-gray-700 hover:bg-gray-600 text-white text-sm transition"
            :style="{ fontSize: buttonFontSize }"
          >
            Fermer
          </button>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from 'vue';
import { getFromCache, setInCache } from '../utils/cache';

// Définition des props attendues
const props = defineProps({
  packageName: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: 'normal',
  },
});

// Variables réactives
const packageInfo = ref(null);
const loading = ref(true);
const error = ref(null);
const showPopup = ref(false);

// Computed properties
const isSmall = computed(() => ['xs', 'mini'].includes(props.size));

const iconSize = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'text-4xl';
    case 'mini':
      return 'text-4xl';
    default:
      return 'text-5xl';
  }
});

const tileClass = computed(() => {
  return isSmall.value ? 'cursor-pointer' : '';
});

// Styles dynamiques
const buttonFontSize = '14px'; // Utilisé dans le template
// 'inputFontSize' a été supprimé car il n'est plus utilisé

// Fonction pour tronquer le texte
const truncate = (text, length) => {
  if (!text) return '';
  return text.length > length ? text.slice(0, length) + '...' : text;
};

// Fonction pour récupérer les informations du package
const fetchPackageInfo = async () => {
  const cacheKey = `npm-package-${props.packageName}`;
  const cachedData = getFromCache(cacheKey);

  if (cachedData) {
    packageInfo.value = cachedData;
    loading.value = false;
    return;
  }

  try {
    const response = await fetch(
      `https://registry.npmjs.org/${encodeURIComponent(props.packageName)}`
    );

    if (!response.ok) {
      throw new Error(
        `Impossible de récupérer les informations du package. Statut : ${response.status}`
      );
    }

    const data = await response.json();

    // Récupérer le nombre de téléchargements
    const statsResponse = await fetch(
      `https://api.npmjs.org/downloads/point/last-month/${encodeURIComponent(
        props.packageName
      )}`
    );
    let downloads = 'Non disponible';

    if (statsResponse.ok) {
      const statsData = await statsResponse.json();
      downloads = statsData.downloads.toLocaleString();
    }

    const processedInfo = {
      name: data.name || 'Nom non disponible',
      description: data.description || 'Pas de description disponible',
      version: data['dist-tags'].latest || 'Version non spécifiée',
      author: data.author?.name || 'Auteur non spécifié',
      npm_url: `https://www.npmjs.com/package/${data.name}`,
      downloads,
    };

    packageInfo.value = processedInfo;
    setInCache(cacheKey, processedInfo);
  } catch (err) {
    error.value = `Erreur : ${err.message}`;
    console.error(err);
  } finally {
    loading.value = false;
  }
};

// Gestionnaire de clic pour les petites tailles
const handleClick = () => {
  if (isSmall.value) {
    showPopup.value = true;
  }
};

// Appel de la fonction de récupération des données au montage du composant
onMounted(fetchPackageInfo);
</script>

<style scoped>
/* Aucun changement nécessaire ici, sauf si vous souhaitez ajuster les styles */
</style>
