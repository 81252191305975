<template>
  <div
    :class="[
      'relative w-full h-full rounded-xl overflow-hidden flex items-center justify-center',
      tileClass,
    ]"
  >
    <!-- Contenu pour les tailles 'xs' et 'mini' -->
    <template v-if="isSmall">
      <a
        :href="link.url"
        target="_blank"
        rel="noopener noreferrer"
        class="flex items-center justify-center w-full h-full group"
      >
        <!-- Icône avec tooltip -->
        <div class="relative">
          <i
            :class="[
              link.icon,
              iconSize,
              'text-white transition-transform duration-300 hover:scale-110',
            ]"
          ></i>
          <!-- Tooltip -->
          <div
            v-if="link.title"
            class="absolute bottom-full mb-2 w-max px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            style="left: 50%; transform: translateX(-50%)"
          >
            {{ link.title }}
          </div>
        </div>
      </a>
    </template>

    <!-- Contenu pour les tailles 'normal' et 'large' -->
    <template v-else>
      <a
        :href="link.url"
        target="_blank"
        rel="noopener noreferrer"
        class="absolute inset-0 flex flex-col justify-center items-center p-4 text-center group"
      >
        <!-- Icône avec tooltip -->
        <div class="relative">
          <i :class="[link.icon, 'text-4xl text-white mb-2']"></i>
          <!-- Tooltip -->
          <div
            v-if="link.title"
            class="absolute bottom-full mb-2 w-max px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            style="left: 50%; transform: translateX(-50%)"
          >
            {{ link.title }}
          </div>
        </div>
        <h3 class="text-white text-lg font-bold mb-1">
          {{ truncate(link.title, 40) }}
        </h3>
        <p class="text-white text-sm opacity-80">
          {{ truncate(link.description, 60) }}
        </p>
      </a>
    </template>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  link: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    default: "normal",
  },
});

const isSmall = computed(() => ["xs", "mini"].includes(props.size));

const iconSize = computed(() => {
  switch (props.size) {
    case "xs":
      return "text-4xl";
    case "mini":
      return "text-3xl";
    default:
      return "text-5xl";
  }
});

const tileClass = computed(() => {
  return [
    "transition-transform duration-300 ease-in-out transform hover:scale-105 shadow-lg",
    props.link.bgColor || "bg-gray-800",
  ];
});

const truncate = (text, length) => {
  if (!text) return "";
  return text.length > length ? text.slice(0, length) + "..." : text;
};
</script>
