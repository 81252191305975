<template>
  <div class="tile-grid" :style="gridStyle">
    <template v-for="item in sortedItems" :key="item.id">
      <div :class="['tile', tileClasses(item)]" :style="tileStyle(item)">
        <!-- Appliquer la classe ici -->
        <div class="tile-content">
          <component
            :is="getComponentType(item.type)"
            v-bind="getComponentProps(item)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, onMounted, onUnmounted } from "vue";
import LinkItem from "./LinkItem.vue";
import ImageTile from "./ImageTile.vue";
import TwitterTile from "./TwitterTile.vue";
import BlogPostTile from "./BlogPostTile.vue";
import NewsletterTile from "./NewsletterTile.vue";
import GitHubRepoTile from "./GitHubRepoTile.vue";
import GithubGistTile from "./GithubGistTile.vue";
import NpmPackageTile from "./NpmPackageTile.vue";

const props = defineProps({
  items: Array,
  layout: Array,
  posts: Array,
});

const windowWidth = ref(window.innerWidth);

const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener("resize", updateWindowWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWindowWidth);
});

const sortedItems = computed(() => {
  return props.layout
    .map((id) => props.items.find((item) => item.id === id))
    .filter(Boolean);
});

const gridStyle = computed(() => ({
  display: "grid",
  gridTemplateColumns:
    windowWidth.value <= 768
      ? "repeat(auto-fill, minmax(80px, 1fr))"
      : "repeat(auto-fill, minmax(150px, 1fr))",
  gap: "10px",
  gridAutoFlow: "dense",
}));

const getComponentType = (type) => {
  const componentMap = {
    link: LinkItem,
    image: ImageTile,
    twitter: TwitterTile,
    blogPost: BlogPostTile,
    newsletter: NewsletterTile,
    githubRepo: GitHubRepoTile,
    githubGist: GithubGistTile,
    npmPackage: NpmPackageTile,
  };
  return componentMap[type] || null;
};

const getComponentProps = (item) => {
  const commonProps = {
    size: item.size,
  };

  switch (item.type) {
    case "link":
      return { ...commonProps, link: item };
    case "image":
      return { ...commonProps, imageUrl: item.imageUrl, altText: item.altText };
    case "twitter":
      return { tweetUrl: item.tweetUrl };
    case "blogPost":
      return { ...commonProps, slug: item.slug, posts: props.posts };
    case "newsletter":
      return commonProps;
    case "githubRepo":
      return { ...commonProps, repoName: item.repoName };
    case "githubGist":
      return { ...commonProps, gistId: item.gistId };
    case "npmPackage":
      return { ...commonProps, packageName: item.packageName };
    default:
      return {};
  }
};

const tileClasses = (item) => {
  const baseClasses =
    "rounded-xl overflow-hidden transition-transform duration-300 ease-in-out shadow-lg";
  return `${baseClasses} ${item.type !== "image" ? "hoverable" : ""} ${
    item.bgColor ? item.bgColor : ""
  }`;
};

const tileStyle = (item) => {
  const isMobile = windowWidth.value <= 768;

  // Vérifier si la tuile est un lien avec uniquement une icône
  const isLinkWithIconOnly =
    item.type === "link" &&
    !item.title &&
    !item.description &&
    item.icon;

  // Déterminer la taille initiale
  let size = item.size || "normal";

  // Ajuster la taille sur mobile si nécessaire
  if (isMobile) {
    if (item.type === "blogPost" || item.type === "newsletter") {
      size = "full"; // Forcer la taille à 'full' pour ces types
    } else if (!isLinkWithIconOnly) {
      if (size === "xs") size = "mini";
      else if (size === "mini") size = "normal";
      else if (size === "normal") size = "large";
      // 'large' et 'full' restent inchangés
    }
  }

  const sizeMap = {
    xs: { gridColumn: "span 1", gridRow: "span 1" },
    mini: { gridColumn: "span 1", gridRow: "span 1" },
    normal: { gridColumn: "span 2", gridRow: "span 2" },
    large: { gridColumn: "span 3", gridRow: "span 3" },
    full: { gridColumn: "1 / -1", gridRow: "span 4" },
  };

  const finalSize = sizeMap[size] || sizeMap.normal;

  const squareTypes = [
    "image",
    "link",
    "newsletter",
    "blogPost",
    "githubRepo",
    "npmPackage",
    "githubGist",
  ];

  if (squareTypes.includes(item.type)) {
    return {
      ...finalSize,
      aspectRatio: "1 / 1",
    };
  }

  return finalSize;
};
</script>

<style scoped>
.tile-grid {
  width: 100%;
}

.tile {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile-content {
  width: 100%;
  height: 100%;
}

.tile.hoverable:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  transform: scale(1.03);
  z-index: 1;
}

.full-tile {
  grid-column: 1 / -1;
}
</style>
