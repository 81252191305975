<template>
  <div
    :class="`twitter-tile tile-${size} bg-gradient-to-br from-blue-400 to-blue-600 hover:shadow-xl transition-shadow duration-300`"
  >
    <blockquote class="twitter-tweet" :data-theme="theme">
      <a :href="tweetUrl">Chargement du tweet...</a>
    </blockquote>
  </div>
</template>

<script>
import { onMounted } from "vue";

export default {
  name: "TwitterTile",
  props: {
    tweetUrl: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: "light",
    },
    size: {
      type: String,
      default: "normal", // Taille par défaut
    },
  },
  setup() {
    onMounted(() => {
      // Charger le script Twitter
      const script = document.createElement("script");
      script.setAttribute("src", "https://platform.twitter.com/widgets.js");
      script.setAttribute("async", "true");
      document.head.appendChild(script);
    });
  },
};
</script>

<style scoped>
.twitter-tile {
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-width: 50%;
  padding: 1rem; /* Ajoutez un peu de padding pour que le dégradé soit visible */
}

.tile-mini {
  min-width: 50%; /* Hauteur pour les tuiles mini */
}

.tile-normal {
  min-width: 100%; /* Hauteur pour les tuiles normales */
}

.tile-large {
  min-width: 150%; /* Hauteur pour les tuiles larges */
}

.twitter-tile:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}
</style>
